<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CARRIAGE_TYPES } from './store'
import { ROUTES as CARRIAGE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import CarriageTable from './CarriageTable'
import CarriageCommonFilters from './CarriageCommonFilters'
import CarriageEdit from './CarriageEdit.vue'

export default {
  name: 'CarriageList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': CarriageTable,
    'common-filters': CarriageCommonFilters,
    'component-edit': CarriageEdit,
    'component-detail': CarriageEdit
  },
  data () {
    return {
      CARRIAGE_ROUTES,
      title: this.$t('Carriages'),
      rolePerm: 'carriage_carriage_list',
      actionEnablePermission: ['carriage_carriage_enable'],
      actionDisablePermission: ['carriage_carriage_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 3 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 3 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 3 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 3 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CARRIAGE_TYPES.GENERIC.carriage.carriage.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CARRIAGE_ROUTES.CARRIAGE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('carriage_carriage_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CARRIAGE_TYPES.GENERIC.carriage.carriage.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CARRIAGE_TYPES.GENERIC.carriage.carriage.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CARRIAGE_TYPES.GENERIC.carriage.carriage.LIST.ACTIONS
    })
  }
}
</script>
