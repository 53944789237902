import { render, staticRenderFns } from "./CarriageCommonFilters.vue?vue&type=template&id=e56187dc&"
import script from "./CarriageCommonFilters.vue?vue&type=script&lang=js&"
export * from "./CarriageCommonFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports